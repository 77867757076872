html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Outfit;
  font-size: 14px;
  line-height: 1;
}

html {
  background-color: #f9ede5;
  background-repeat: no-repeat;
}

html.light-bg {
  background: linear-gradient(88deg, #e0eafc -124.49%, #fff 227.99%);
}

:root {
  --sds-color-black-100: #0c0c0d0d;
  --sds-color-black-1000: #0c0c0d;
  --sds-color-black-200: #0c0c0d1a;
  --sds-color-black-300: #0c0c0d33;
  --sds-color-black-400: #0c0c0d66;
  --sds-color-black-500: #0c0c0db2;
  --sds-color-black-600: #0c0c0dcc;
  --sds-color-black-700: #0c0c0dd9;
  --sds-color-black-800: #0c0c0de5;
  --sds-color-black-900: #0c0c0df2;
  --sds-color-brand-100: #f5f5f5;
  --sds-color-brand-1000: #111;
  --sds-color-brand-200: #e6e6e6;
  --sds-color-brand-300: #d9d9d9;
  --sds-color-brand-400: #b3b3b3;
  --sds-color-brand-500: #757575;
  --sds-color-brand-600: #444;
  --sds-color-brand-700: #383838;
  --sds-color-brand-800: #2c2c2c;
  --sds-color-brand-900: #1e1e1e;
  --sds-color-gray-100: #f5f5f5;
  --sds-color-gray-1000: #111;
  --sds-color-gray-200: #e6e6e6;
  --sds-color-gray-300: #d9d9d9;
  --sds-color-gray-400: #b3b3b3;
  --sds-color-gray-500: #757575;
  --sds-color-gray-600: #444;
  --sds-color-gray-700: #383838;
  --sds-color-gray-800: #2c2c2c;
  --sds-color-gray-900: #1e1e1e;
  --sds-color-green-100: #ebffee;
  --sds-color-green-1000: #062d1b;
  --sds-color-green-200: #cff7d3;
  --sds-color-green-300: #aff4c6;
  --sds-color-green-400: #85e0a3;
  --sds-color-green-500: #14ae5c;
  --sds-color-green-600: #009951;
  --sds-color-green-700: #008043;
  --sds-color-green-800: #02542d;
  --sds-color-green-900: #024023;
  --sds-color-pink-100: #fcf1fd;
  --sds-color-pink-1000: #3f1536;
  --sds-color-pink-200: #fae1fa;
  --sds-color-pink-300: #f5c0ef;
  --sds-color-pink-400: #f19edc;
  --sds-color-pink-500: #ea3fb8;
  --sds-color-pink-600: #d732a8;
  --sds-color-pink-700: #ba2a92;
  --sds-color-pink-800: #8a226f;
  --sds-color-pink-900: #57184a;
  --sds-color-red-100: #fee9e7;
  --sds-color-red-1000: #300603;
  --sds-color-red-200: #fdd3d0;
  --sds-color-red-300: #fcb3ad;
  --sds-color-red-400: #f4776a;
  --sds-color-red-500: #ec221f;
  --sds-color-red-600: #c00f0c;
  --sds-color-red-700: #900b09;
  --sds-color-red-800: #690807;
  --sds-color-red-900: #4d0b0a;
  --sds-color-slate-100: #f3f3f3;
  --sds-color-slate-1000: #242424;
  --sds-color-slate-200: #e3e3e3;
  --sds-color-slate-300: #cdcdcd;
  --sds-color-slate-400: #b2b2b2;
  --sds-color-slate-500: #949494;
  --sds-color-slate-600: #767676;
  --sds-color-slate-700: #5a5a5a;
  --sds-color-slate-800: #434343;
  --sds-color-slate-900: #303030;
  --sds-color-white-100: #ffffff0d;
  --sds-color-white-1000: #fff;
  --sds-color-white-200: #ffffff1a;
  --sds-color-white-300: #fff3;
  --sds-color-white-400: #fff6;
  --sds-color-white-500: #ffffffb2;
  --sds-color-white-600: #fffc;
  --sds-color-white-700: #ffffffd9;
  --sds-color-white-800: #ffffffe5;
  --sds-color-white-900: #fffffff2;
  --sds-color-yellow-100: #fffbeb;
  --sds-color-yellow-1000: #401b01;
  --sds-color-yellow-200: #fff1c2;
  --sds-color-yellow-300: #ffe8a3;
  --sds-color-yellow-400: #e8b931;
  --sds-color-yellow-500: #e5a000;
  --sds-color-yellow-600: #bf6a02;
  --sds-color-yellow-700: #975102;
  --sds-color-yellow-800: #682d03;
  --sds-color-yellow-900: #522504;
  --sds-size-blur-100: 4px;
  --sds-size-depth-0: 0px;
  --sds-size-depth-025: 1px;
  --sds-size-depth-100: 4px;
  --sds-size-depth-1200: 48px;
  --sds-size-depth-200: 8px;
  --sds-size-depth-400: 16px;
  --sds-size-depth-800: 32px;
  --sds-size-depth-negative-025: -1px;
  --sds-size-depth-negative-100: -4px;
  --sds-size-depth-negative-1200: -48px;
  --sds-size-depth-negative-200: -8px;
  --sds-size-depth-negative-400: -16px;
  --sds-size-depth-negative-800: -32px;
  --sds-size-icon-large: 40px;
  --sds-size-icon-medium: 32px;
  --sds-size-icon-small: 24px;
  --sds-size-radius-100: 4px;
  --sds-size-radius-200: 8px;
  --sds-size-radius-400: 16px;
  --sds-size-radius-full: 9999px;
  --sds-size-space-0: 0px;
  --sds-size-space-050: 2px;
  --sds-size-space-100: 4px;
  --sds-size-space-1200: 48px;
  --sds-size-space-150: 6px;
  --sds-size-space-1600: 64px;
  --sds-size-space-200: 8px;
  --sds-size-space-2400: 96px;
  --sds-size-space-300: 12px;
  --sds-size-space-400: 16px;
  --sds-size-space-4000: 160px;
  --sds-size-space-600: 24px;
  --sds-size-space-800: 32px;
  --sds-size-space-negative-100: -4px;
  --sds-size-space-negative-200: -8px;
  --sds-size-space-negative-300: -12px;
  --sds-size-space-negative-400: -16px;
  --sds-size-space-negative-600: -24px;
  --sds-size-stroke-border: 1px;
  --sds-size-stroke-focus-ring: 2px;
}

h1, h2, h3, h4, h5, h6, p {
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}

* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  font-family: Outfit;
}

.container {
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 1200px) {
  .container {
    width: 1150px;
  }
}

.wide-container {
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 1200px) {
  .wide-container {
    width: 1293px;
  }
}

.x-wide-container {
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 768px) {
  .x-wide-container {
    width: 750px;
  }
}

@media (width >= 992px) {
  .x-wide-container {
    width: 970px;
  }
}

@media (width >= 1200px) {
  .x-wide-container {
    width: 1383px;
  }
}

.narrow-container {
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 1200px) {
  .narrow-container {
    width: 1065px;
  }
}

.splide__slide {
  flex-direction: column;
  display: flex;
}
/*# sourceMappingURL=index.0eb00081.css.map */
